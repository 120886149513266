import Image from "next/image";
import Link from "next/link";
import quickSaveImg from "../../../public/icons/power.png";
import targetImg from "../../../public/icons/target.png";
import { commafy } from "@/src/utils/format-text";
import clsx from "clsx";

export type SavingCardType = {
  id: string;
  amountPaid: number;
  goalAchieve: string;
  savingGoal: string;
  trustFundKidId: string | null;
  cardNo: number;
  LinkToDetail?: boolean;
};

const SavingCard = ({
  savingCard: {
    id,
    savingGoal,
    trustFundKidId,
    amountPaid,
    goalAchieve,
    cardNo,
    LinkToDetail = false,
  },
}: {
  savingCard: SavingCardType;
}) => {
  return (
    <li
      className={clsx(
        "bg-savings-card-texture bg-cover bg-center h-40 rounded-lg flex justify-center items-center p-4 py-6 ",
        cardNo % 2 == 0 ? "bg-[#001514]" : " bg-[#109428]"
      )}
    >
      <Link
        href={LinkToDetail ? "/quick-save/detail/" + cardNo : "#"}
        className="cursor-pointer w-full h-full flex flex-col justify-between"
      >
        <div className="w-full top-5 flex items-center justify-between">
          <div className="py-1 px-2 rounded-3xl bg-white text-xs uppercase">
            {savingGoal}
          </div>
          {trustFundKidId && (
            <div className="flex items-center gap-2">
              <Image
                width={20}
                height={20}
                alt="child savings avatar"
                src="/images/child-profile-plhd.png"
                className=" ring-slate-300 rounded-full"
              />
              <h4 className="text-xs font-[600] text-white underline">
                Kazeem Ayomide
              </h4>
            </div>
          )}
        </div>
        <div className="w-full flex items-center justify-between">
          <div className="space-y-1">
            <div className="flex items-center gap-2">
              <p className="text-white text-xs font-bold">Quick Save</p>{" "}
              <Image src={quickSaveImg} alt="spark" />
            </div>
            <div className="text-white flex items-end">
              <span className="font-extrabold text-xl tracking-wide">
                {commafy(amountPaid, true)}.
              </span>
              <span className="font-medium text-xs -translate-y-1"> 00</span>
            </div>
          </div>
          <div className="space-y-1">
            <div className="flex items-center gap-2">
              <p className="text-white text-xs font-medium">Goal achieved</p>
              <Image src={targetImg} alt="target" />
            </div>
            <div className="flex items-end gap-0.5">
              <span className="text-white font-bold text-xl">
                {goalAchieve}
              </span>
              <span className="text-white font-medium text-sm">%</span>
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
};

export { SavingCard };
