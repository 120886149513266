"use client";
import { useRouter } from "next/navigation";
import React from "react";
import { IoArrowBack, IoMenu, IoNotificationsOutline } from "react-icons/io5";
import { SideBar } from "../side-bar";
import { NunitoFont } from "@/src/fonts/fonts-config";

interface DashboardWrapperProps {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
}

const DashboardWrapper = ({
  children,
  title,
  subtitle,
}: DashboardWrapperProps) => {
  const router = useRouter();
  return (
    <div
      className={`flex flex-col items-center p-5 min-h-screen ${NunitoFont.className}`}
    >
      <div className="w-full max-w-sm bg-[#f5fffb] px-5 has-[*:checked]:overflow-hidden relative h-full flex-1 flex flex-col">
        <div className="flex justify-between items-center">
          {/* refactor this and turn the component into a server component */}
          <IoArrowBack
            className="cursor-pointer"
            onClick={() => router.back()}
            size={25}
          />
          <div className="flex flex-shrink-0 items-center">
            <img
              className="h-[100px] w-auto"
              src="/svgs/logo_blue.svg"
              alt="Your Company"
            />
          </div>
          <div className="flex items-center gap-6">
            <IoNotificationsOutline size={25} />
            <label htmlFor="sidebar-toggle">
              <IoMenu size={25} />
            </label>
          </div>
        </div>
        <main className="flex-grow">
          {title && (
            <h1 className="font-bold text-xl text-[#091B66]">{title}</h1>
          )}

          {subtitle && <p className="my-2 text-[#434249]">{subtitle}</p>}
          {children}
        </main>
        <SideBar />
      </div>
    </div>
  );
};

export { DashboardWrapper };
