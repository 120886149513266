import { HeyWowFont } from "src/fonts/fonts-config";
import React from "react";
import Spinner from "./spinner";

interface BaseButtonProps {
  children: React.ReactNode;
  extraPadding?: string;
  fullWidth?: boolean;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  variant?:
    | "danger"
    | "primary"
    | "primaryOutline"
    | "mild"
    | "grey"
    | "mildDanger";
  loading?: boolean; // Added loading prop
}

const DashboardBaseButton = ({
  children,
  extraPadding,
  fullWidth,
  onClick,
  type = "button",
  disabled = false,
  variant = "primary",
  loading = false, // Default to false
}: BaseButtonProps) => {
  const baseStyles =
    "relative inline-flex items-center justify-center gap-x-1.5 rounded-md py-3 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2";

  const variantStyles = {
    danger:
      "bg-[#CC0000] text-white hover:bg-red-600 focus-visible:outline-red-500",
    mildDanger:
      "bg-[#fff] text-[#CC0000] hover:bg-gray-100 focus-visible:outline-gray-200",
    primary:
      "bg-[#264FFA] text-white hover:bg-indigo-400 focus-visible:outline-indigo-500",
    primaryOutline:
      "bg-[#264FFA] text-white hover:bg-[#1535b6] focus-visible:outline-indigo-500 border border-white",
    mild: "bg-[#fff] text-[#434249] hover:bg-gray-100 focus-visible:outline-gray-200 border border-[#BDBDBD]",
    grey: "bg-[#A0A0A0] text-white hover:bg-gray-500 focus-visible:outline-gray-500",
  };

  const padding = extraPadding ?? "px-4";
  const width = fullWidth ? "w-full" : "w-auto";
  const disabledStyles = "disabled:bg-slate-400 disabled:cursor-not-allowed";

  return (
    <button
      type={type}
      disabled={disabled || loading} // Disable button if loading
      onClick={onClick}
      className={`${baseStyles} ${variantStyles[variant]} ${padding} ${width} ${disabledStyles} ${HeyWowFont.className}`}
    >
      {loading ? <Spinner /> : children}
    </button>
  );
};

export { DashboardBaseButton };
