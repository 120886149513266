"use client";

import { usePathname, useRouter } from "next/navigation";
import { createPortal } from "react-dom";
import { IoClose } from "react-icons/io5";
// import BaseButton from "src/components/landing-page/button/base-button";
import { PiTarget } from "react-icons/pi";
import { FiLogOut } from "react-icons/fi";
import { FiHome } from "react-icons/fi";
import { FiUser } from "react-icons/fi";
import { PiClockCounterClockwiseFill } from "react-icons/pi";
import BaseButton from "../landing-page/button/base-button";
import Link from "next/link";
import { clsx } from "clsx";
import { clearToken } from "@/src/utils/storage";
const NavDetails = [
  {
    icons: <FiHome />,
    path: "/home",
    linkText: "Home",
  },
  {
    icons: <PiTarget />,
    path: "/quick-save/create",
    linkText: "Savings",
  },
  {
    icons: <FiUser />,
    path: "/child-profile",
    linkText: "My Profile",
  },
  {
    icons: <PiClockCounterClockwiseFill />,
    path: "/transactions",
    linkText: "Transactions",
  },
];

function SideBar() {
  return (
    <>
      <input type="checkbox" id="sidebar-toggle" className="hidden peer" />
      <section
        className={
          "hidden peer-checked:flex peer-checked:right-0 peer-checked:opacity-100 opacity-0  justify-end bg-black/45 z-30 absolute h-full w-full md:max-w-sm top-0 -right-[500px] overflow-hidden transition-opacity delay-300 duration-300 ease-in-out"
        }
      >
        {/* <div className="flex z-10 absolute h-full w-full " /> */}
        <div
          className={
            "w-full h-full py-8 px-4 md:p-8 overflow-hidden max-w-xs bg-white"
          }
        >
          <SidebarHeader />
          <SideBarNavLinks />

          <LogoutBtn />
        </div>
      </section>
    </>
  );
}

const SidebarHeader = () => {
  return (
    <div className="flex justify-between my-10 px-4">
      <h3 className="font-bold">Menu</h3>
      <label htmlFor="sidebar-toggle">
        <IoClose size={25} />
      </label>
    </div>
  );
};

const LogoutBtn = () => {
  const router = useRouter();

  function logout() {
    clearToken();
    router.push("/");
  }
  return (
    <div className="my-10">
      <BaseButton variant="mildDanger" onClick={logout}>
        <FiLogOut className="text-[#CC0000] mr-1" />
        Logout
      </BaseButton>
    </div>
  );
};

const SideBarNavLinks = () => {
  const pathname = usePathname();

  return (
    <nav>
      <ul className="space-y-1 w-full">
        {NavDetails.map((el) => (
          <Link
            key={el.path}
            className={clsx(
              pathname === el.path && " text-gray-700 bg-gray-100",
              "flex items-center gap-2 rounded-lg px-4 py-2 text-#7D7E81 hover:bg-gray-100 hover:text-gray-700"
            )}
            href={el.path}
          >
            {el.icons}
            <span className="text-sm font-medium"> {el.linkText} </span>
          </Link>
        ))}
      </ul>
    </nav>
  );
};

export { SideBar };
