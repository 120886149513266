import React from "react";
import { GoArrowDownLeft } from "react-icons/go";

interface TransactionProps {
  name: string;
  time: string;
  amount: string;
  isLast?: boolean;
}

const Transaction = ({ name, time, amount, isLast }: TransactionProps) => {
  return (
    <div>
      <div className="w-full flex items-start justify-between text-[#434249] my-5">
        <div className="flex items-center gap-3">
          <div className="size-[40px] bg-[#F4F4F4] flex justify-center items-center rounded-full">
            <GoArrowDownLeft color="#00C008" size={22} />
          </div>
          <div>
            <h1 className="font-[500] text-[14px]">{name}</h1>
            <p className="text-[#868686] text-[12px] font-[400]">{time}</p>
          </div>
        </div>
        <h1 className="text-[14px] font-[600]">+N{amount}</h1>
      </div>
      {!isLast && <div className="border border-b border-[#F2F2F2]" />}
    </div>
  );
};

export { Transaction };
