"use client";

import { getToken, getUserInfoFromStorage } from "@/src/utils/storage";
import { useRouter } from "next/navigation";
import { useEffect } from "react";

interface AuthProtectProps {
  children: React.ReactNode;
}

function RouteProtect({ children }: AuthProtectProps) {
  const router = useRouter();
  const userData = getUserInfoFromStorage();

  useEffect(() => {
    const token = getToken();
    if (!token) {
      // Redirecting the user to dynamic login  page
      if (userData?.type === "super_admin") {
        router.push("/admin/log-in");
      } else if (userData?.type === "vendor") {
        router.push("/vendor/log-in");
      } else {
        router.push("/log-in");
      }
    }
  }, []);

  return <div>{children}</div>;
}

export { RouteProtect };
