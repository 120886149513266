"use client";
import { useRouter } from "next/navigation";
import React from "react";
import { IoArrowBack, IoNotificationsOutline, IoMenu } from "react-icons/io5";

const Navbar = () => {
  const router = useRouter();

  return (
    <div className="flex justify-between items-center">
      <IoArrowBack
        className="cursor-pointer"
        onClick={() => router.back()}
        size={25}
      />
      <div className="flex flex-shrink-0 items-center">
        <img
          className="h-[100px] w-auto"
          src="/svgs/logo_blue.svg"
          alt="Your Company"
        />
      </div>
      <div className="flex items-center gap-6">
        <IoNotificationsOutline size={25} />
        <IoMenu size={25} />
      </div>
    </div>
  );
};

export { Navbar };
